.about-page-desc {
  margin-top: 120px;

  @media (max-width: 460px) {
    margin-top: 80px;
  }
  &__wrap {
    position: relative;
    height: 900px;

    @media (max-width: 980px) {
      height: unset;
    }

    @media (max-width: 460px) {
      height: unset;
      padding: 0 10px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    text-transform: uppercase;
    color: #FFFFFF;

    @media (max-width: 460px) {
      font-size: 32px;
      line-height: 39px;
    }
  }
  &__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 700px;
    height: 100%;

    @media (max-width: 1440px) {
      width: calc(50% - 50px);
      right: 50px;
    }

    @media (max-width: 980px) {
      width: 100%;
      position: static;
      margin-top: 20px;
    }
    @media (max-width: 460px) {
      width: unset;
      position: static;
      margin-top: 20px;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__text {
    width: 657px;
    margin: 30px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    a {
      color: #ff5900;
    }

    @media (max-width: 1440px) {
      width: 50%;
      padding-right: 30px;
    }

    @media (max-width: 980px) {
      width: 100%;
    }

    @media (max-width: 460px) {
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
      line-height: 17px;
    }
    p {
      &:first-child {
        @media (max-width: 460px) {
          margin-top: 20px;
        }
      }
    }
  }
}
