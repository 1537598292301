.about-page-deco {
  display: flex;
  width: 98vw;
  margin-top: 60px;
  overflow: hidden;

  @media (max-width: 460px) {
    margin-top: 30px;
  }

  div {
    white-space: nowrap;
    font-weight: 500;
    font-size: 200px;
    line-height: 244px;
    color: #201e1e;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #ffffff;
    animation: marquee-text 5s linear infinite;

    @media (max-width: 460px) {
      font-size: 65.5012px;
      line-height: 80px;
      -webkit-text-stroke-width: 1px;
    }
  }
}

@keyframes marquee-text {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
